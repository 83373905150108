import React, { Component } from 'react'

export class Parteners extends Component {
    render() {
        return (
            <div>
                {/* <!-- section 5 Partners --> */}
                <h1 className="MainFont text-center mt-5">Our Partners</h1>
                <section className="client-logos">
                    <img src="assets/images/clients/ADES.png" alt="ADES Logo" />
                    <img src="assets/images/clients/Alex trans.png" alt="Alex Trans Logo" />
                    <img src="assets/images/clients/alexshipyard.png" alt="Alex Shipyard Logo" />
                    <img src="assets/images/clients/Amoc.jpeg" alt="Amoc Logo" />
                    <img src="assets/images/clients/EDC.png" alt="EDC Logo" />
                    <img src="assets/images/clients/Elab.avif" alt="Elab Logo" />
                    <img src="assets/images/clients/Ethydco.png" alt="Ethydco Logo" />
                    <img src="assets/images/clients/GS E&C.jpeg" alt="GS E&C Logo" />
                    <img src="assets/images/clients/Midor.png" alt="Midor Logo" />
                    <img src="assets/images/clients/Oils expert.jpeg" alt="Oils Expert Logo" />
                    <img src="assets/images/clients/Onspec.webp" alt="Onspec Logo" />
                    <img src="assets/images/clients/Petrojet.png" alt="Petrojet Logo" />
                    <img src="assets/images/clients/Petromint .jpeg" alt="Petromint Logo" />
                    <img src="assets/images/clients/Rashpetcho.jpeg" alt="Rashpetcho Logo" />
                    <img src="assets/images/clients/Red Sea port Safaga.jpeg" alt="Red Sea Port Safaga Logo" />
                    <img src="assets/images/clients/Saipem.jpeg" alt="Saipem Logo" />
                    <img src="assets/images/clients/SIDPEC.jpg" alt="SIDPEC Logo" />
                    <img src="assets/images/clients/Sumed.jpeg" alt="Sumed Logo" />
                </section>

            </div>
        )
    }
}

export default Parteners
