import React, { Component } from 'react';
import Navbar from '../Common/Navbar';
import Footer from '../Common/Footer';
import Copyright from '../Common/Copyright';
import ImageGallery from '../AllDetails/ImageGallery'; // Correct import
import projects from '../AllDetails/ProDataArray';

class AllProjects extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="AllProjects marginspec">
                    {projects.map((project, index) => (
                        <div key={index} className="projectContainer">
                            <div className="projectcard mt-2">
                                <h3 className="card-title">{project.title}</h3>
                                <div className="procardcontect">
                                    <div className="proImg">
                                        <ImageGallery images={project.images} />
                                    </div>
                                    <div className="PROdescription">
                                        <img src='/assets/images/logo.png' />
                                        <p className="card-body" dangerouslySetInnerHTML={{ __html: project.description }} />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Footer />
                <Copyright />
            </div>
        );
    }
}

export default AllProjects;
