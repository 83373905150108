import React, { Component } from 'react'

export class Carasoul extends Component {
    render() {
        return (
            <div>
                {/* <!-- section 2 carasoul --> */}
                <div
                    id="carouselExampleCaptions"
                    data-bs-ride="carousel"
                    data-bs-interval="5000"
                    className="carousel slide"
                >
                    <div className="carousel-indicators">
                        <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                        ></button>
                        <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                        ></button>
                        <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                        ></button>
                        <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="3"
                            aria-label="Slide 4"
                        ></button>
                        <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="4"
                            aria-label="Slide 5"
                        ></button>
                        <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="5"
                            aria-label="Slide 6"
                        ></button>
                    </div>



                    <div className="carousel-inner">

                        <div className="carousel-item active">
                            <img
                                src="assets/images/asset integrity.png"
                                className="d-block w-100"
                                alt="asset integrity management service"
                            />
                            <div className="carousel-caption MainFont d-none d-md-block">
                                <h5>Asset Integrity Management Service</h5>
                                <p>Optimize your investments with our expert asset management services. Delve into a tailored strategy designed to meet your financial goals and secure your future. Trust us for the ultimate in asset growth and stability.</p>
                            </div>
                        </div>


                        <div className="carousel-item">
                            <img
                                src="assets/images/Lifting Engineering.jpeg"
                                className="d-block w-100"
                                alt="Lifting Engineering Solutions"
                            />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Lifting Engineering Solutions</h5>
                                <p>Elevate your operations with our cutting-edge lifting engineering solutions. Discover how our innovative designs and technology ensure ultimate safety and efficiency. Delve into customized solutions that lift your projects to new heights.</p>
                            </div>
                        </div>


                        <div className="carousel-item">
                            <img
                                src="assets/images/steelstructure inspection.jpg"
                                className="d-block w-100"
                                alt="Inspection Solutions"
                            />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Inspection Solutions</h5>
                                <p>Ensure peak performance with our advanced inspection solutions. Delve into comprehensive assessments designed to identify and address potential issues. Trust us for the ultimate in precision and reliability.</p>
                            </div>
                        </div>


                        <div className="carousel-item">
                            <img src="assets/images/Rope-Access.jpg" className="d-block w-100" alt="Rope Access Services" />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Rope Access Services</h5>
                                <p>Reach new heights with our professional rope access services. Delve into efficient, safe, and cost-effective solutions for challenging tasks. Experience the ultimate in expert climbing and inspection techniques.</p>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <img src="assets/images/instrument scrapping services.jpg" className="d-block w-100" alt="Specified Services" />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Specified Services</h5>
                                <p>Discover our specified services tailored to your unique needs. Delve into customized solutions that address your specific challenges. Experience the ultimate in precision and expertise for exceptional results.</p>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <img src="assets/images/Supply.jpeg" className="d-block w-100" alt="Supply Services" />
                            <div className="carousel-caption d-none d-md-block">
                                <h5>Supply Services</h5>
                                <p>Streamline your operations with our reliable supply services. Experience a seamless process for all your sourcing needs. Trust us for timely and efficient supply chain solutions.</p>
                            </div>
                        </div>

                    </div>




                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev"
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next"
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        )
    }
}

export default Carasoul
