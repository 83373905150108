import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import AllServices from "./AllDetails/AllServices";
import AllProjects from "./AllDetails/AllProjects";
import About from "./Others/About";
import ContactPage from "./Others/ContactPage";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/services",
    element: <AllServices />
  },
  {
    path: "/projects",
    element: <AllProjects />
  },
  {
    path: "/about",
    element: <About />
  },
  {
    path: "/contact",
    element: <ContactPage />
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router}>
  </RouterProvider>
);