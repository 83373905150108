import React, { Component } from 'react'

export class Contact extends Component {
    render() {
        const { showcase } = this.props;
        return (
            <div>
                {/* <!-- section 6  Contact --> */}
                <div className={showcase}>
                    <h1 className="MainFont text-center mt-5 mb-5">Contact</h1>
                </div>
                <div className='Contact'>
                    <div className="mx-5 d-flex row ">
                        <div className="map col-md-6 col-sm-12 text-center">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1297.5429285506673!2d29.974233017188297!3d31.246351503860485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5dacd183eeaf5%3A0x51f6303195589ff8!2sBANQUE%20MISR!5e0!3m2!1sen!2seg!4v1724408994709!5m2!1sen!2seg" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                        <div className="ContactLinks mt-5 col-sm-12 col-md-6">
                            <ul>
                                <li
                                    className="row card d-flex align-content-center justify-content-center"
                                >
                                    <a
                                        className=""
                                        target="_blank"
                                        href="https://www.google.com/maps/place/31.262603054933436,30.013811873048233"
                                    >
                                        <p className="mb-0">
                                            <i className="fa-solid fa-location-dot"></i>

                                            <b>Address : </b> 705 El Horriya Road, Louran, Qesm AR Ramel, Alexandria Governorate

                                        </p>
                                    </a>
                                </li>
                                {/* <li
                                    className="row card d-flex align-content-center justify-content-center"
                                >
                                    <a
                                        className=""
                                        target="_blank"
                                        href="https://www.facebook.com/profile.php?id=61561388205229"
                                    >
                                        <p className="mb-0">
                                            <i className="icon1 text-primary fa-brands fa-facebook"></i>

                                            Facebook Page
                                        </p>
                                    </a>
                                </li> */}

                                <li
                                    className="row card d-flex align-content-center justify-content-center"
                                >
                                    <a className="" target="_blank" href="https://wa.me/+201559187000">
                                        <p className="mb-0">
                                            <i className="fa-brands fa-whatsapp icon1 text-success"></i>
                                            <b>WhatsApp :</b>  01559187000
                                        </p>
                                    </a>
                                </li>
                                <li
                                    className="row card d-flex align-content-center justify-content-center"
                                >
                                    <a className="" target="_blank" href="https://wa.me/+201143326749">
                                        <p className="mb-0">
                                            <i className="fa-brands fa-whatsapp icon1 text-success"></i>
                                            <b>WhatsApp :</b>  01143326749
                                        </p>
                                    </a>
                                </li>
                                <li
                                    className="row card d-flex align-content-center justify-content-center"
                                >
                                    <a className="" target="_blank" href="tel:01559187000">
                                        <p className="mb-0">
                                            <i className="fa-solid fa-phone icon1 text-danger"></i>
                                            <b>Phone :</b> 01559187000
                                        </p>
                                    </a>
                                </li>
                                <li
                                    className="row card d-flex align-content-center justify-content-center"
                                >
                                    <a
                                        className=""
                                        target="_blank"
                                        href="mailto:technicalsupport@alarabiaccc.com"
                                    >
                                        <p className="mb-0">
                                            <i className="fa-solid fa-envelope icon1 text-warning"></i>
                                            technicalsupport@alarabiaccc.com
                                        </p>
                                    </a>
                                </li>
                                <li
                                    className="row card d-flex align-content-center justify-content-center"
                                >
                                    <a
                                        className=""
                                        target="_blank"
                                        href="mailto:hr@alarabiaccc.com"
                                    >
                                        <p className="mb-0">
                                            <i className="fa-solid fa-envelope icon1 text-warning"></i>
                                            hr@alarabiaccc.com
                                        </p>
                                    </a>
                                </li>
                                <li
                                    className="row card d-flex align-content-center justify-content-center"
                                >
                                    <a
                                        className=""
                                        target="_blank"
                                        href="mailto:omar.hanafy@alarabiaccc.com"
                                    >
                                        <p className="mb-0">
                                            <i className="fa-solid fa-envelope icon1 text-warning"></i>
                                            omar.hanafy@alarabiaccc.com
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact
