import React from 'react'
import Navbar from './Common/Navbar'
import Carasoul from './Home/Carasoul'
import Services from './Home/Services'
import Projects from './Home/Projects'
import Parteners from './Home/Parteners'
import Contact from './Home/Contact'
import Footer from './Common/Footer'
import Copyright from './Common/Copyright'
import Branches from './Home/Branches'

function App() {
  return (
    <div>
      <Navbar />
      <Carasoul />
      <Services />
      <Projects/>
      <Parteners />
      <Branches/>
      <Contact />
      <Footer />
      <Copyright />
    </div>
  )
}


export default App
