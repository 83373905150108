import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <div>
                {/* <!-- Section 7 : Footer --> */}
                <footer className="footer mt-5">
                    <div className="footer-container">
                        {/* <!-- First Column: Quick Links --> */}
                        <div className="footer-col text-start ms-5 mt-3">
                            <h2 className="mb-3">Quick Links</h2>
                            <div className="d-flex justify-content-center align-content-center">
                                <ul className="col footer-links">
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/services">Services</a></li>
                                    <li><a href="/projects">Projects</a></li>
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="/contact">Contact</a></li>
                                </ul>

                                {/* <ul className="col footer-links">
                                    <li><a href="#home">Privacy Policy</a></li>
                                    <li><a href="#services">Terms of Service</a></li>
                                    <li><a href="#services">FAQ</a></li>
                                    <li><a href="#services">Support</a></li>
                                </ul> */}
                            </div>
                        </div>

                        {/* <!-- Second Column: Brand and Contact Icons --> */}
                        <div className="footer-client-logos">
                            <img src="assets/images/clients/ADES.png" alt="ADES Logo" />
                            <img src="assets/images/clients/Alex trans.png" alt="Alex Trans Logo" />
                            <img src="assets/images/clients/alexshipyard.png" alt="Alex Shipyard Logo" />
                            <img src="assets/images/clients/Amoc.jpeg" alt="Amoc Logo" />
                            <img src="assets/images/clients/EDC.png" alt="EDC Logo" />
                            <img src="assets/images/clients/Elab.avif" alt="Elab Logo" />
                            <img src="assets/images/clients/Ethydco.png" alt="Ethydco Logo" />
                            <img src="assets/images/clients/GS E&C.jpeg" alt="GS E&C Logo" />
                            <img src="assets/images/clients/Midor.png" alt="Midor Logo" />
                            <img src="assets/images/clients/Oils expert.jpeg" alt="Oils Expert Logo" />
                            <img src="assets/images/clients/Onspec.webp" alt="Onspec Logo" />
                            <img src="assets/images/clients/Petrojet.png" alt="Petrojet Logo" />
                            <img src="assets/images/clients/Petromint .jpeg" alt="Petromint Logo" />
                            <img src="assets/images/clients/Rashpetcho.jpeg" alt="Rashpetcho Logo" />
                            <img src="assets/images/clients/Red Sea port Safaga.jpeg" alt="Red Sea Port Safaga Logo" />
                            <img src="assets/images/clients/Saipem.jpeg" alt="Saipem Logo" />
                            <img src="assets/images/clients/SIDPEC.jpg" alt="SIDPEC Logo" />
                            <img src="assets/images/clients/Sumed.jpeg" alt="Sumed Logo" />
                        </div>
                        {/* <!-- Third Column: Auxiliary Links --> */}
                        {/* Latest Projects */}
                        <div className="footer-col mt-3 d-flex align-items-center flex-column">

                            <div className="footer-col mt-3">
                                <div className="footer-brand">
                                    <img
                                        style={{ backgroundColor: "white", width: "100px", height: "100px" }}
                                        src="assets/images/logo.png"
                                        alt="Brand Logo"
                                        className="brand-logo"
                                    />
                                    <h2 className='mt-5'>Arab Contracting and Construction Company</h2>
                                </div>
                            </div>
                            <div className="footer-contact">
                                <a href="mailto:omar.hanafy@alarabiaccc.com" className="contact-icon">
                                    <i className="fa-solid fa-envelope icon1 text-warning"></i>
                                </a>
                                <a href="tel:+01143326749" className="contact-icon">
                                    <i className="fa-solid fa-phone icon1 text-danger"></i>
                                </a>
                                <a href="https://wa.me/+201559187000" className="contact-icon">
                                    <i className="fa-brands fa-whatsapp icon1 text-success"></i>
                                </a>
                                {/* <a href="https://facebook.com" className="contact-icon">
                                    <i className="icon1 text-primary fa-brands fa-facebook"></i>
                                </a> */}
                                {/* <a href="https://twitter.com" className="contact-icon">
                                    <i className="fa-brands fa-linkedin text-info"></i>
                                </a> */}
                                {/* <a href="https://instagram.com" className="contact-icon">
                                    <i className="fa-brands fa-instagram text-danger"></i>
                                </a> */}
                            </div>

                            {/* <h4>Latest Projects</h4> */}
                            {/* <ul className="footercards overflow-y-scroll">
                                <li className="card mt-2">
                                    <a href="#terms" className="card-link">
                                        <div className="card-content">
                                            <img
                                                className="card-image"
                                                src="assets/images/pping.jpg"
                                                alt="Project Image"
                                            />
                                            <p className="card-text">
                                                Our latest project - Al-Mehwar Hospital is HERE
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li className="card mt-2">
                                    <a href="#terms" className="card-link">
                                        <div className="card-content">
                                            <img
                                                className="card-image"
                                                src="assets/images/pping.jpg"
                                                alt="Project Image"
                                            />
                                            <p className="card-text">
                                                Our latest project - Al-Mehwar Hospital is HERE
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li className="card mt-2">
                                    <a href="#terms" className="card-link">
                                        <div className="card-content">
                                            <img
                                                className="card-image"
                                                src="assets/images/pping.jpg"
                                                alt="Project Image"
                                            />
                                            <p className="card-text">
                                                Our latest project - Al-Mehwar Hospital is HERE
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer
