import React, { Component } from 'react';

export class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSmallScreen: window.innerWidth <= 600
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({ isSmallScreen: window.innerWidth <= 600 });
    }

    render() {

        const { isSmallScreen } = this.state;


        return (
            <div>
                {/* Section 1: Navbar */}
                <nav className="navbar fixed-top navbar-expand-md MainFont">
                    <div className="container-fluid">
                        <div className="row w-100">
                            {/* Logo */}
                            <div className="col-sm-10 col-md-12 col-lg-6 d-flex justify-content-between align-items-center">
                                <a href='/'>
                                    <img className="logo" src="assets/images/logo.png" alt="Company Logo" />
                                </a>

                                {/* <a className="navbar-brand text-wrap" href="/">
                                    Arab Contracting and Construction Company
                                </a> */}

                                <a className="navbar-brand text-wrap" href="/">
                                    {isSmallScreen ? 'ACC' : 'Arab Contracting and Construction Company'}
                                </a>

                                {/* Navbar Toggler */}
                                <button
                                    className="navbar-toggler bg-light col-2"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>



                            {/* Nav Items */}
                            <div className="col-lg-6 col-md-12 d-flex justify-content-center">
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mb-2 mb-lg-0 d-flex justify-content-center w-100">
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" href="/">
                                                HOME
                                            </a>
                                        </li>


                                        {/* <li className="nav-item dropdown">
                                            <a
                                                className="nav-link dropdown-toggle"
                                                href="#"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                SERVICES
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">Action</a></li>
                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                            </ul>
                                        </li> */}


                                        <li className="nav-item">
                                            <a className="nav-link" href="/services">SERVICSE</a>
                                        </li>


                                        <li className="nav-item">
                                            <a className="nav-link" href="projects">PROJECTS</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/about">ABOUT US</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href='/contact' aria-disabled="true">CONTACT</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;
