import React, { Component } from 'react'

export class Copyright extends Component {
    render() {
        return (
            <div>
                {/* <!-- Section 8 : Copyright --> */}
                <div
                    className="copyright d-flex justify-content-between align-items-center"
                >
                    {/* <!-- Adjust height as needed --> */}
                    <div className="text-center">
                        <p className="mb-0">
                            Copyright © ACC Company 2024
                        </p>
                    </div>

                    <div className="text-end text-center">
                        <a style={{
                            textDecoration: 'none',
                            color: 'inherit', 
                        }} target="_blank" href="https://wa.me/+201553711196">
                            <p className="mb-0">
                                <span>
                                    Developed By M-TECH Co.
                                    &nbsp;
                                </span>
                                <i className="fa-brands fa-whatsapp icon1 text-success"></i>
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Copyright
