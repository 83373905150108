import React from 'react';
import { useNavigate } from 'react-router-dom';


const Services = () => {
    const navigate = useNavigate();
    const handleClick = (serviceId) => {
        navigate('/services', { state: { scrollTo: serviceId } });
    };

    return (
        <div className="Homepage">
            {/* <!-- section 3 Services --> */}
            <div>
                <h1 className="MainFont text-center mt-5">Services</h1>
            </div>

            <div className="ServicesContainer">
                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('Asset')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/asset integrity.png" alt="Asset Integrity Management" />
                    </div>
                    <p className="sertitle"> INTEGRITY MANAGEMENT SERVICES</p>
                </div>

                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('LIFTING')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/Lifting Engineering.jpeg" alt="LIFTING ENGINEERING SOLUTIONS" />
                    </div>
                    <p className="sertitle">LIFTING ENGINEERING SOLUTIONS</p>
                </div>

                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('INSPECTION')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/steelstructure inspection.jpg" alt="INSPECTION SOLUTIONS" />
                    </div>
                    <p className="sertitle">INSPECTION SOLUTIONS</p>
                </div>

                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('MARINE')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/MARINE SERVICES.jpg" alt="MARINE SERVICES SOLUTIONS" />
                    </div>
                    <p className="sertitle">MARINE SERVICES SOLUTIONS</p>
                </div>

                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('ROPE')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/Rope-Access.jpg" alt="ROPE ACCESS SERVICES" />
                    </div>
                    <p className="sertitle">ROPE ACCESS SERVICES</p>
                </div>


                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('Scaffolding')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/Scaffolding.jpg" alt="Scaffolding Activities" />
                    </div>

                    <p className="sertitle">Scaffolding Activities</p>
                </div>

                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('Surface')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/surface preparation.jpg" alt="Surface Preparation and Painting Activities" />
                    </div>
                    <p className="sertitle">Surface Preparation and Painting Activities</p>
                </div>


                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('Firefighting')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/Firefighting Activities.jpeg" alt="Firefighting Activities" />
                    </div>

                    <p className="sertitle">Firefighting Activities</p>
                </div>


                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('Design')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/design tanks.jpeg" alt="Design tanks and steel structure" />
                    </div>
                    <p className="sertitle">Design</p>
                </div>


                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('Construction')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/welding.jpg" alt="Construction Activities" />
                    </div>

                    <p className="sertitle">Construction Activities</p>
                </div>


                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('SPECIALIZED')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/OVERHAULING & MAINTENANCE.png" alt="SPECIALIZED SERVICES – OVERHAULING & MAINTENANCE" />
                    </div>
                    <p className="sertitle">SPECIALIZED SERVICES – OVERHAULING & MAINTENANCE</p>
                </div>

                {/* <!-- item --> */}
                <div className="partition" onClick={() => handleClick('SUPPLY')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/Supply.jpeg" alt="SUPPLY SERVICES" />
                    </div>

                    <p className="sertitle">SUPPLY SERVICES</p>
                </div>


                {/* <!-- item --> */}
                {/* <div className="partition" onClick={() => handleClick('Asset')}>
                    <div className="imagecont">
                        <div>
                            <div className="outbord d-flex justify-content-center align-items-center">
                                <p>Service Details</p>
                            </div>
                        </div>
                        <img src="assets/images/asset integrity.png" alt="TRAINING SERVICES" />
                    </div>

                    <p className="sertitle">TRAINING SERVICES</p>
                </div> */}

            </div>

            <div>
                <div className="d-flex justify-content-center">
                    <a href='/services' className="allbtn btn" type="button">Show All Services</a>
                </div>
            </div>
        </div>
    );
};

export default Services;
