import React from 'react';
import Navbar from '../Common/Navbar';
import Footer from '../Common/Footer';
import Copyright from '../Common/Copyright';

class About extends React.Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="about-us">
                    <section className="vision">
                        <h2>Vision</h2>
                        <p>Delivering Excellence and 1st Class Solutions supporting Business partners.</p>
                    </section>
                    <section className="mission">
                        <h2>Mission</h2>
                        <p>Providing reliable & innovative solutions enabling customers to outperform & add value by maximizing their performance and minimizing the operational cost.</p>
                    </section>
                    <section className="core-values">
                        <h2>Core Values</h2>
                        <ul>
                            <li>Commitment</li>
                            <li>Result and Quality-driven</li>
                            <li>Integrity</li>
                            <li>Innovation</li>
                            <li>Partnership</li>
                        </ul>
                    </section>

                </div>
                <Footer />
                <Copyright />
            </div>
        );
    }
}

export default About;
