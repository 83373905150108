import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Common/Navbar'
import Footer from '../Common/Footer'
import Copyright from '../Common/Copyright'
import { Element } from 'react-scroll';
import { scroller } from 'react-scroll';

const AllServices = () => {

    const location = useLocation();
    const scrollTo = location.state?.scrollTo;

    useEffect(() => {
        if (scrollTo) {
            scroller.scrollTo(scrollTo, {
                smooth: true,
                offset: -80, // Adjust based on your header or positioning
                duration: 100, // Scroll duration
            });
            const element = document.getElementById(scrollTo);
            if (element) {
                element.classList.add('vivid-animation');

                // Remove animation class after 2 seconds
                setTimeout(() => {
                    element.classList.remove('vivid-animation');
                }, 2000);
            }
        }
    }, [scrollTo]);

    return (
        <div>
            <Navbar />
            <div className='AllservicesPage'>
                <div className="ServicesContainer">


                    {/* <!-- item --> */}
                    <Element className="partition" id='Asset' name='Asset' >
                        <div className="imagecont">
                            <img src="assets/images/asset integrity.png" alt="Asset Integrity Management" />
                        </div>
                        <p className="sertitle"> INTEGRITY MANAGEMENT SERVICES</p>
                        <div >
                            <ul className="description">
                                <li>SPS Project support utilizing IACS Approval with ABS, DNV, BV & LR.</li>
                                <li>OUT Hull Gauging for Vessels and offshore structure.</li>
                                <li>SPS Marine Projects support (Offshore Rigs / Jack Up).</li>
                                <li>SPS UWILD Above water Inspection (NDT - Rope Access & General Maintenance).</li>
                                <li>IACS Classification Society Inspection Support (ABS/DNV/BV/LR/IRS).</li>
                                <li>Rig Acceptance Audit (Condition Evaluation and Audit) offshore/Onshore.</li>
                                <li>Rig Acceptance support including Shipyard scope support according to class requirements, clients, and rig operational requirements.</li>
                                <li>Rig Inspection (Pre-Client Acceptance) as per Schedule G.</li>
                                <li>Project Engineering Support.</li>
                                <li>Inspection, Testing, and Certification for all Rig Equipment & Assets.</li>
                                <li>Steel Structure Assessment & Evaluation.</li>
                            </ul>
                        </div>



                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='LIFTING' name='LIFTING' >
                        <div className="imagecont">
                            <img src="assets/images/Lifting Engineering.jpeg" alt="LIFTING ENGINEERING SOLUTIONS" />
                        </div>
                        <p className="sertitle">LIFTING ENGINEERING SOLUTIONS</p>
                        <div >
                            <ul className="description">
                                <li>Lifting Equipment Management Services.</li>
                                <li>Lifting Gear Inspection Services.</li>
                                <li>API RP 2D Crane Inspection & Certification.</li>
                                <li>Crane Safe Load Indicator Calibration & Verification.</li>
                                <li>Lifting Planning, Supervision, Coaching, and Risk Assessment.</li>
                                <li>Load Calculations.</li>
                                <li>Load Testing.</li>
                                <li>Lifting Technical Support.</li>
                                <li>Overhead Crane Repair & Maintenance Services.</li>
                                <li>Manufacturing & Repair of Skids / Racks / Lifting Frames.</li>
                                <li>Rope Installation & Removal.</li>
                                <li>EMI - NDT of Wire Rope Inspection - Cranes & Life Boat.</li>
                            </ul>
                        </div>



                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='INSPECTION' name='INSPECTION' >
                        <div className="imagecont">
                            <img src="assets/images/steelstructure inspection.jpg" alt="INSPECTION SOLUTIONS" />
                        </div>
                        <p className="sertitle">INSPECTION SOLUTIONS</p>
                        <div >
                            <ul className="description">
                                <li>Tubular & OCTG Inspection Services.</li>
                                <li>Drilling Equipment, Hoisting, and Handling tools.</li>
                                <li>NDT Services (MPI/PT/ET/UT/UTG/VT).</li>
                                <li>Advanced NDT (ECI/ Phased Array / Corrosion Mapping).</li>
                                <li>OUT Hull Gauging Measurements (ABS/DNV).</li>
                                <li>Pressure Testing & PRV's Verifications.</li>
                            </ul>
                        </div>


                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='MARINE' name='MARINE' >
                        <div className="imagecont">
                            <img src="assets/images/MARINE SERVICES.jpg" alt="MARINE SERVICES SOLUTIONS" />
                        </div>
                        <p className="sertitle">MARINE SERVICES SOLUTIONS</p>
                        <div >
                            <ul className="description">
                                <li>Marine Engineering Consultancy.</li>
                                <li>Marine Compliance Audit.</li>
                                <li>Marine Technical Support.</li>
                                <li>Marine Crew Competency Assessment.</li>
                                <li>Unit Customization and Upgrade.</li>
                                <li>Stability Calculations review.</li>
                                <li>Regulatory Compliance.</li>
                                <li>Insurance and Claim Management.</li>
                                <li>IACS & Flag technical Support.</li>
                                <li>Audit for Compliance with Marine governing regulations.</li>
                                <li>Competency Training.</li>
                                <li>Shipyard Scope Preparation / Scope Pre-Acceptance Audit/ Survey.</li>
                            </ul>
                        </div>



                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='ROPE' name='ROPE' >
                        <div className="imagecont">
                            <img src="assets/images/Rope-Access.jpg" alt="ROPE ACCESS SERVICES" />
                        </div>
                        <p className="sertitle">ROPE ACCESS SERVICES</p>
                        <div >
                            <ul className="description">
                                <li>Offshore SPS Projects / UWILD Projects Support.</li>
                                <li>Rope Access Services and working at heights.</li>
                                <li>Annual Dropped Object Survey / Fall Protection Survey.</li>
                                <li>Derrick Inspection - API 4G CAT III.</li>
                                <li>Inspection Requirements at Heights.</li>
                                <li>General Maintenance.</li>
                                <li>Hydro / Sand Blasting.</li>
                                <li>Cleaning / Painting.</li>
                                <li>Installation & Removal.</li>
                                <li>Welding at Heights.</li>
                                <li>Efficient Façade Cleaning - Building.</li>
                            </ul>
                        </div>
                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='Scaffolding' name='Scaffolding'>
                        <div className="imagecont">
                            <img src="assets/images/Scaffolding.jpg" alt="Scaffolding Activities" />
                        </div>

                        <p className="sertitle">Scaffolding Activities</p>
                        <div >
                            <ul className="description">
                                <li>Design process.</li>
                                <li>Working at heights (Access anywhere safe).</li>
                                <li>Competent inspectors and scaffolders (STI) Cup lock system.</li>
                                <li>Pipe and couplers system.</li>
                                <li>Inspection for scaffolding material.</li>
                            </ul>
                        </div>

                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='Surface' name='Surface' >
                        <div className="imagecont">
                            <img src="assets/images/surface preparation.jpg" alt="Surface Preparation and Painting Activities" />
                        </div>
                        <p className="sertitle">Surface Preparation and Painting Activities</p>
                        <div >
                            <ul className="description">
                                <li>Blasting SA2 1⁄2 (Silica/Garnet).</li>
                                <li>Mechanical brushing ST3.</li>
                                <li>Painting as per manufacturer recommendation:
                                    <ul>
                                        <li>Manual.</li>
                                        <li>Compressed air spray.</li>
                                        <li>Airless machine.</li>
                                    </ul>
                                </li>
                                <li>Painting for special chemical:
                                    <ul>
                                        <li>High/low temperature.</li>
                                        <li>Cathodic protection.</li>
                                        <li>Fireproof paints.</li>
                                        <li>Food industrial paints.</li>
                                    </ul>
                                </li>
                                <li>Insulation activities:
                                    <ul>
                                        <li>Foam insulation.</li>
                                        <li>Rockwall insulation.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>


                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='Firefighting' name='Firefighting' >
                        <div className="imagecont">
                            <img src="assets/images/Firefighting Activities.jpeg" alt="Firefighting Activities" />
                        </div>

                        <p className="sertitle">Firefighting Activities</p>
                        <div >
                            <ul className="description">
                                <li>Design for fire fighting and fire alarm system.</li>
                                <li>Supply fire fighting pumps, valves, and fittings (UL&FM).</li>
                                <li>Installation for firefighting system.</li>
                                <li>Coupling system is available.</li>
                            </ul>
                        </div>

                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='Design' name='Design' >
                        <div className="imagecont">
                            <img src="assets/images/design tanks.jpeg" alt="Design tanks and steel structure" />
                        </div>
                        <p className="sertitle">Design</p>
                        <div >
                            <ul className="description">
                                <li>Design for steel structure component:
                                    <ul>
                                        <li>Steel structure.</li>
                                        <li>Tanks.</li>
                                        <li>Piping system.</li>
                                    </ul>
                                </li>
                                <li>Civil and foundation design.</li>
                                <li>Interior and external designs.</li>
                                <li>Loads calculations and building analysis.</li>
                            </ul>
                        </div>


                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='Construction' name='Construction' >
                        <div className="imagecont">
                            <img src="assets/images/welding.jpg" alt="Construction Activities" />
                        </div>

                        <p className="sertitle">Construction Activities</p>
                        <div >
                            <ul className="description">
                                <li>Fabrication and installation:
                                    <ul>
                                        <li>Steel structure.</li>
                                        <li>Tanks.</li>
                                        <li>Piping system.</li>
                                    </ul>
                                </li>
                                <li>Welding activities for special alloys.</li>
                                <li>Install corrugated sheets sides and roof.</li>
                                <li>Installation equipment.</li>
                            </ul>
                        </div>

                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='SPECIALIZED' name='SPECIALIZED' >
                        <div className="imagecont">
                            <img src="assets/images/OVERHAULING & MAINTENANCE.png" alt="SPECIALIZED SERVICES – OVERHAULING & MAINTENANCE" />
                        </div>
                        <p className="sertitle">SPECIALIZED SERVICES – OVERHAULING & MAINTENANCE</p>
                        <div >
                            <ul className="description">
                                <li>Maintenance & Repair Projects Management.</li>
                                <li>Maintenance & Repair of Offshore Platform Structure.</li>
                                <li>Overhauling of Engines.</li>
                                <li>Laser Alignment and Vibration Analysis.</li>
                                <li>Overhauling & Maintenance of Engines and Electrical Motors.</li>
                                <li>Overhauling & Maintenance of Pumps.</li>
                                <li>Overhauling & Maintenance of Machinery and Winches.</li>
                                <li>Overhauling & Maintenance of Generators.</li>
                            </ul>
                        </div>


                    </Element>

                    {/* <!-- item --> */}
                    <Element className="partition" id='SUPPLY' name='SUPPLY'>
                        <div className="imagecont">
                            <img src="assets/images/Supply.jpeg" alt="SUPPLY SERVICES" />
                        </div>

                        <p className="sertitle">SUPPLY SERVICES</p>
                        <div >
                            <ul className="description">
                                <li>Crane Wire Rope Supply.</li>
                                <li>Drill Line Ropes.</li>
                                <li>Lifting Gears Supply.</li>
                                <li>Supplying Pad Eyes.</li>
                                <li>Lifting Equipment & Accessories Supply.</li>
                                <li>General Oilfield Tools Supply.</li>
                                <li>Lubricants Supply.</li>
                                <li>Working at Heights Tools.</li>
                                <li>Crane Safe Load Indicators.</li>
                                <li>Load Cells.</li>
                                <li>Drill Pipe / Drilling Tools / BOP ☐ PPE.</li>
                            </ul>
                        </div>

                    </Element>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    )
}

export default AllServices