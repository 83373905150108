import React from 'react';

const Branches = () => {
  return (
    <div>
      <h1 className="text-center mt-5">Our Branches</h1>
      <div className="map-container">
        <img src="assets/images/worldmap2.png" alt="World Map" className="map-image" />
        <div className="location egypt" title="Egypt">
          <i className="iconStyle fa-solid fa-location-dot">
            <span className='county'>Egypt</span>
          </i>
        </div>
        <div className="location saudi-arabia" title="Saudi Arabia">
          <i className="iconStyle fa-solid fa-location-dot">
            <span className='county'>SA</span>
          </i>
        </div>
        <div className="location emirates" title="United Arab Emirates">
          <i className="iconStyle fa-solid fa-location-dot">
            <span className='county'>UAE</span>
          </i>
        </div>
        <div className="location kuwait" title="Kuwait">
          <i className="iconStyle fa-solid fa-location-dot">
            <span className='county'>Kuwait</span>
          </i>
        </div>
      </div>
    </div>
  );
};

export default Branches;
