const projects = [
    {
        title: 'Alexandria National Company (Anarpk): Ironwork and Painting',
        description: `
            <ul>
                <li>Ironwork, trusses, and painting.</li>
            </ul>
        `,
        images: [
            '/assets/images/projects/Anarpic/img (1).jpg',
            '/assets/images/projects/Anarpic/img (2).jpg',
            '/assets/images/projects/Anarpic/img (3).jpg',
            '/assets/images/projects/Anarpic/img (4).jpg',
            '/assets/images/projects/Anarpic/img (5).jpg',
            '/assets/images/projects/Anarpic/img (6).jpg',
            '/assets/images/projects/Anarpic/img (7).jpg',
        ],
    },
    {
        title: 'Petroment: Thermal Insulation',
        description: `
            <ul>
                <li>Thermal insulation work on hot surfaces within the Belayim Petroleum Company.</li>
            </ul>
        `,
        images: [
            '/assets/images/projects/petroment insulation lines/1.jpg',
            '/assets/images/projects/petroment insulation lines/2.jpg',
            '/assets/images/projects/petroment insulation lines/3.jpg',
        ],
    },
    {
        title: 'Sidi Kerir Petrochemicals: Salt Concentration Unit Renovation',
        description: `
            <ul>
                <li>Replacement and renovation of the salt concentration unit with ethylene.</li>
                <li>Welding work.</li>
                <li>Replacement of fire pipeline networks.</li>
                <li>Insulation work.</li>
            </ul>
        `,
        images: [
            '/assets/images/projects/Salt Concentration/img (1).png',
            '/assets/images/projects/Salt Concentration/img (2).png',
        ],
    },
    {
        title: 'Sidi Kerir Petrochemicals: Fire Pipeline Replacement',
        description: `
            <ul>
                <li>Replacement of some fire pipelines.</li>
                <li>Modification of tank lines and gas lines.</li>
            </ul>
        `,
        images: [
            '/assets/images/projects/sidikrer pertochemicals/img (1).png',
            '/assets/images/projects/sidikrer pertochemicals/img (2).png',
            '/assets/images/projects/sidikrer pertochemicals/1.jpg',
            '/assets/images/projects/sidikrer pertochemicals/2.jpg',
        ],
    },
    {
        title: 'Beheira Governorate: Ironwork and Floating Bridges',
        description: `
            <ul>
                <li>Ironwork and floating bridges for vehicle bridges.</li>
            </ul>
        `,
        images: [
            '/assets/images/projects/Ironwork/img (1).png',
            '/assets/images/projects/Ironwork/img (2).png',
        ],
    },
    {
        title: 'Alexandria Petroleum Companies: Mechanical Maintenance',
        description: `
            <ul>
                <li>Maintenance of all mechanical work across most petroleum companies in Alexandria.</li>
            </ul>
        `,
        images: [
            '/assets/images/projects/maintanance works/img (1).png',
            '/assets/images/projects/maintanance works/img (2).png',
            '/assets/images/projects/maintanance works/img (3).png',
            '/assets/images/projects/maintanance works/img (4).png',
            '/assets/images/projects/maintanance works/img (5).png',
        ],
    },
    {
        title: 'Alexandria National Company (Anarbk): Fence and Concrete Work',
        description: `
            <ul>
                <li>Construction of a barbed-wire fence and concrete work with a length of 650 meters within the company.</li>
            </ul>
        `,
        images: [
            '/assets/images/projects/wall works/1.jpg',
        ],
    },
    {
        title: 'Equipment Supply For Midor & Sidi Kerir Petrochemicals.',
        description: `
            <ul>
                <li>Provision of welders, burners, marine workers, stone burners, and welding machines for all overhauls and maintenance in the following companies:</li>
                <li>Midor Refining Company.</li>
                <li>Sidi Kerir Petrochemicals.</li>
            </ul>
        `,
        images: [
            '/assets/images/projects/Equipment Supply/2.jpg',
            '/assets/images/projects/Equipment Supply/1.jpg',
        ],
    },
    {
        title: 'Equipment Supply for Petroment & Anarbk',
        description: `
            <ul>
                <li>Petroleum Maintenance Company (Petromont).</li>
                <li>National Petrochemical Company (Anarbk).</li>
            </ul>
        `,
        images: [
            '/assets/images/projects/supply equipments for pertroment/1.jpg',
            '/assets/images/projects/supply equipments for pertroment/2.jpg',
        ],
    },
];

export default projects;
