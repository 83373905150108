import React, { Component } from 'react';
import ImageGallery from '../AllDetails/ImageGallery'; // Correct import
import projects from '../AllDetails/ProDataArray';

class AllProjects extends Component {
    render() {
        const HomePageProjects = projects.slice(0, 2);

        return (
            <div>
                {/* <!-- section 4 Projects--> */}
                <h1 className="text-center  mt-5">Our Projects</h1>
                <div className="AllProjects">
                    {HomePageProjects.map((project, index) => (
                        <div key={index} className="projectContainer">
                            <div className="projectcard mt-2">
                                <h3 className="card-title">{project.title}</h3>
                                <div className="procardcontect">
                                    <div className="proImg">
                                        <ImageGallery images={project.images} />
                                    </div>
                                    <div className="PROdescription">
                                        <img src='/assets/images/logo.png' />
                                        <p className="card-body" dangerouslySetInnerHTML={{ __html: project.description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div class="d-flex justify-content-center">
                    <a href='/projects' class="allbtn btn" type="button">Show All Projects</a>
                </div>
            </div>
        );
    }
}

export default AllProjects;

