import React, { Component } from 'react';

class ImageGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainImage: props.images && props.images[0], // Safely access the first image
        };
    }

    changeImage = (src) => {
        this.setState({ mainImage: src });
    };

    render() {
        const { mainImage } = this.state;
        const { images = [] } = this.props; // Default to an empty array if images is undefined

        return (
            <div className="container my-4">
                <div className="row">
                    <div className="col-12">
                        <img
                            id="mainImage"
                            src={mainImage}
                            alt="Main Image"
                            className="large-image img-fluid"
                        />
                    </div>
                </div>
                <div className="row thumbnail-images">
                    {images.map((src, index) => (
                        <img
                            key={index}
                            src={src}
                            alt={`Image ${index + 1}`}
                            className={`img-fluid ${mainImage === src ? 'active' : ''}`}
                            onClick={() => this.changeImage(src)}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default ImageGallery;
