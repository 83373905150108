import React, { Component } from 'react'
import Navbar from '../Common/Navbar';
import Footer from '../Common/Footer';
import Copyright from '../Common/Copyright';
import Contact from '../Home/Contact';

export class ContactPage extends Component {
  render() {
    return (
      <div>
        <Navbar/>
        <div style={{marginTop:"100px"}}>
            <Contact  showcase = "dnone"/>
        </div>
        <Footer/>
        <Copyright/>
      </div>
    )
  }
}

export default ContactPage
